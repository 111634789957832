import React from 'react';
import PropTypes from 'prop-types';
import routerEvents from 'next-router-events';

import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import Button from '../../01_atoms/Button';
import LogoWhite from '../../../public/static/icons/logos/logo-white.svg';
import IconToggle from '../../01_atoms/IconToggle';

import styles from './index.module.scss';

class OverlayHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      currentLocation: '',
    };
    this.onRouterCompleteToggle = this.onRouterCompleteToggle.bind(this);
  }

  componentDidMount() {
    // The mobile menus stay toggled on page change, so force a reset.
    routerEvents.on('routeChangeComplete', this.onRouterCompleteToggle);
    this.updateCurrentLocation();
  }

  componentWillUnmount() {
    routerEvents.off('routeChangeComplete', this.onRouterCompleteToggle);
  }

  componentDidUpdate() {
    this.updateCurrentLocation();
  }

  updateCurrentLocation() {
    if (window && window.location) {
      if (this.state.currentLocation !== window.location.pathname) {
        this.setState({ currentLocation: window.location.pathname });
      }
    }
  }

  closeMenu() {
    if (this.state.toggled) {
      this.setState({ toggled: false });
    }
  }

  onRouterCompleteToggle() {
    this.closeMenu();
  }

  render() {
    const {
      homeNextLink = null,
      pageTypeLogo = null,
      pageTypeVariant = 'one-planet',
      navigationContents = [],
      cta = null,
      isTop = true,
    } = this.props;
    const { toggled, currentLocation } = this.state;
    const isSubpage = pageTypeLogo.nextLink.url !== currentLocation;
    const siteLogoIsVisible = !toggled && isTop && !isSubpage;

    const classes = [
      'site-overlay-header',
      styles['site-overlay-header'],
      styles[`site-overlay-header--variant-${pageTypeVariant}`],
      toggled ? 'site-overlay-header--menu-expanded' : '',
      isSubpage ? 'site-overlay-header--full-width' : '',
    ];

    return (
      <header className={classes.join(' ')} data-test="activism-header-overlay">
        {homeNextLink && (
          <div
            className="page-type__site-logo"
            data-test={`activism-header-overlay-site-logo--${
              siteLogoIsVisible ? 'visible' : 'invisible'
            }`}
          >
            <Link {...homeNextLink}>
              <a tabIndex={siteLogoIsVisible ? 0 : -1}>
                <LogoWhite
                  alt="Concern Worldwide"
                  className="logo-white"
                  aria-label="Concern Worldwide"
                />
              </a>
            </Link>
          </div>
        )}

        <div className="page-type__navbar">
          <div className="page-type__navbar-top">
            <div className="page-type__navbar-top-left">
              {pageTypeLogo && pageTypeLogo.url && (
                <div className="page-type__logo">
                  <Link {...pageTypeLogo.nextLink}>
                    <a tabIndex={0}>
                      <img src={pageTypeLogo.url} alt={pageTypeLogo.alt} />
                    </a>
                  </Link>
                </div>
              )}

              {navigationContents.length > 0 && (
                <nav className="page-type__menu-desktop" role="navigation">
                  {navigationContents.map((link) => (
                    <div className="menu-item" key={`desktop-menu-link-${link.label}`}>
                      <Link {...link.nextLink}>
                        <a
                          tabIndex={0}
                          className={link.nextLink.url === currentLocation ? 'is-active' : ''}
                        >
                          {link.label}
                        </a>
                      </Link>
                    </div>
                  ))}
                </nav>
              )}
            </div>

            <div className="page-type__navbar-top-right">
              {cta && cta.label && cta.nextLink && (
                <Link {...cta.nextLink}>
                  <Button tag="button" href={cta.nextLink.url} className="page-type__cta-desktop">
                    {cta.label}
                  </Button>
                </Link>
              )}

              <div className="page-type__menu-toggle">
                <IconToggle
                  className="navigation"
                  assetUntoggled="/static/icons/stacked-group-dark.svg"
                  assetToggled="/static/icons/close-dark.svg"
                  altToggled="Close menu"
                  altUntoggled="Open menu"
                  width={25}
                  height={25}
                  toggled={toggled}
                  onClick={() => this.setState({ toggled: !toggled })}
                />
              </div>
            </div>
          </div>
          <div className="page-type__navbar-bottom">
            <div className="page-type__dropdown">
              {navigationContents.length > 0 && (
                <nav className="page-type__menu-mobile" role="navigation">
                  {navigationContents.map((link) => (
                    <div className="menu-item" key={`mobile-menu-link-${link.label}`}>
                      <Link {...link.nextLink}>
                        <a tabIndex={0}>{link.label}</a>
                      </Link>
                    </div>
                  ))}
                </nav>
              )}

              {cta && cta.label && cta.nextLink && (
                <Link {...cta.nextLink}>
                  <Button tag="button" href={cta.nextLink.url} className="page-type__cta-mobile">
                    {cta.label}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const propTypes = {
  homeNextLink: PropTypes.shape({
    url: PropTypes.string,
    href: PropTypes.string,
    as: PropTypes.string,
  }),
  pageTypeLogo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  pageTypeVariant: PropTypes.oneOf(['one-planet', 'project-us']),
  navigationContents: PropTypes.arrayOf(
    PropTypes.shape({
      nextLink: PropTypes.shape(linkPropTypes),
      label: PropTypes.string,
    }),
  ),
  cta: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes),
    label: PropTypes.string,
  }),
  isTop: PropTypes.bool,
};

OverlayHeader.propTypes = propTypes;

export { propTypes };

export default OverlayHeader;
